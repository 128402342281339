<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/n1.png`" />
    </div>
    <div class="main-content">
      <!-- <div class="about_left">
        <div @click="setcItem(item)"
             :class="['about_left_item',cItem==item?'actived':'']"
             v-for="item in data1"
             :key="item">
          {{item}}
        </div>
      </div> -->
      <div class="about_right">
        <template v-if="news.length>0">
          <div class="right_item"
               v-for="item in news"
               :key="item.id">
            <div class="cover">
              <img :src="item.picture" />
            </div>
            <div class="right_item_content">
              <h1 class="title">{{item.title}}</h1>
              <p class="desc"
                 v-html="item.introduce"></p>
              <a target="_blank"
                 :href="item.originUrl"
                 class="source">来源：{{item.originName}}</a>
            </div>
            <div class="right_item_opt">
              <span class="date">{{item.outputDate}}</span>
              <button class="see-detail"
                      @click="setDetail(item)">阅读详情</button>
            </div>
          </div>
          <div style="text-align: right; margin-top: 20px">
            <a-pagination v-model="pageParams.pageNumber"
                          :total="total"
                          :page-size="pageParams.pageSize"
                          :show-total="total => `共 ${total} 条数据`"
                          @showSizeChange="onShowSizeChange"
                          @change="onPageChange">
              <template slot="buildOptionText"
                        slot-scope="props">
                <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                <span v-if="props.value === '50'">全部</span>
              </template>
            </a-pagination>
          </div>
        </template>
        <div v-else>
          <a-empty description="暂无数据" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews as getNewsReq } from '@/api/http/index.js'

export default {

  props: {},
  data () {
    return {
      cItem: '公司新闻',
      data1: [
        '公司新闻',
        '产品新闻'
      ],
      news: [],
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      total: 50,
      pageParams: {
        pageNumber: 1,
        pageSize: 10,
      }
    }
  },
  created () {
    this.getNews()
  },
  methods: {
    setcItem (item) {
      this.cItem = item
    },
    setDetail (item) {
      this.$router.push({
        path: "/ndetails/" + item.id,
      });
    },
    onShowSizeChange (current, pageSize) {
      this.pageParams.pageSize = pageSize;
      this.getNews()
    },
    async getNews () {
      const params = this.pageParams
      const res = await getNewsReq(params)
      if (res.code === 200) {
        this.news = res.data.content
        this.total = res.data.totalElements
      }
    },
    onPageChange (page, pageSize) {

      getNews()
    }
  },
}
</script>
<style lang='less' scoped>
.main-content {
  width: 100%;
  padding: 0 390px 20px;
  min-height: 210px;
}
// .about_left {
//   flex: 1;
//   .about_left_item {
//     width: 272px;
//     height: 52px;
//     font-size: 16px;
//     text-align: center;
//     line-height: 52px;
//     cursor: pointer;
//     font-weight: 500;
//   }
//   .actived {
//     background: #1d65e3;
//     color: #fff;
//   }
// }
.about_right {
  width: 100%;
}
.right_item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #efefef;
  .cover {
    display: block;
    width: 300px;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right_item_content {
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 20px;
    > h1 {
      line-height: 50px;
      padding: 0;
      margin: 0;
      font-size: 28px;
    }
    .desc {
      color: rgba(0, 0, 0, 0.7);
      line-height: 28px;
    }
    .source {
      color: #4f80af;
    }
  }
  .right_item_opt {
    align-self: flex-start;
    padding-top: 10px;
    .see-detail {
      display: block;
      background: #333;
      color: #fff;
      padding: 6px 30px;
      border-radius: 6px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>